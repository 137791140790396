import React, { useState } from 'react';
import Image from 'next/image';
import { motion, AnimatePresence } from 'framer-motion';
import AnimatedButton from './AnimatedButton';
import styles from './BimiInteractive.module.scss';

const providers = [
  {
    name: 'Gmail',
    image: '/images/mbp-gmail.svg',
    eyebrow: 'Google Mail',
    headline: 'Gmail Adopts BIMI and VMC',
    content:
      'Gmail fully supports BIMI, showcasing verified brand logos. With DMARC enforcement and VMC implementation, Gmail enhances email authentication and provides users with a more secure, visually rich inbox experience.',
  },
  {
    name: 'Yahoo',
    image: '/images/mbp-yahoo.svg',
    eyebrow: 'Yahoo Mail',
    headline: 'Yahoo Pioneers BIMI Support',
    content:
      'As an early adopter, Yahoo implements BIMI, displaying verified logos in user inboxes. This feature enhances brand recognition and trust, providing Yahoo users with a more engaging and secure email environment.',
  },
  {
    name: 'AOL',
    image: '/images/mbp-aolmail.svg',
    eyebrow: 'AOL Mail',
    headline: 'AOL Enhances Email with BIMI',
    content:
      'AOL has integrated BIMI support, improving brand recognition in emails. This implementation allows AOL users to see verified brand logos, enhancing trust and providing a more engaging email experience.',
  },
  {
    name: 'Outlook',
    image: '/images/mbp-outlook.svg',
    eyebrow: 'Microsoft Outlook',
    headline: 'Outlook Implements BIMI for Enhanced Branding',
    content:
      'Microsoft Outlook implements BIMI for improved email authentication and branding. This feature allows Outlook users to see verified brand logos, enhancing the visual appeal of their inbox while boosting email security.',
  },
  {
    name: 'Apple Mail',
    image: '/images/mbp-icloud-mail.svg',
    eyebrow: 'Apple Mail',
    headline: 'Apple Mail Explores BIMI Integration',
    content:
      "Apple Mail is exploring BIMI integration to enhance email security and brand recognition. While not yet fully implemented, this move signals Apple's commitment to improving email authentication and providing users with a more visually rich and secure inbox experience.",
  },
  {
    name: 'Fastmail',
    image: '/images/mbp-fastmail.svg',
    eyebrow: 'Fastmail',
    headline: 'Fastmail Embraces BIMI Technology',
    content:
      'Fastmail supports BIMI, enhancing email security and brand visibility. By implementing this standard, Fastmail offers its users improved authenticity verification and a more visually appealing inbox experience.',
  },
  {
    name: 'ProtonMail',
    image: '/images/mbp-protonmail.svg',
    eyebrow: 'Proton Mail',
    headline: 'ProtonMail Strengthens Security with BIMI',
    content:
      "ProtonMail enhances email security with BIMI support. Known for its focus on privacy, ProtonMail's adoption of BIMI further reinforces email authenticity and provides users with visual brand verification.",
  },
  {
    name: 'Zoho Mail',
    image: '/images/mbp-zoho.svg',
    eyebrow: 'Zoho Mail',
    headline: 'Zoho Mail Adopts BIMI for Improved User Experience',
    content:
      'Zoho Mail supports BIMI, enhancing email security and brand visibility. This implementation allows Zoho users to benefit from improved email authentication and enjoy a more visually rich inbox with verified brand logos.',
  },
  {
    name: 'MailFence',
    image: '/images/mbp-mailfence.svg',
    eyebrow: 'MailFence',
    headline: 'MailFence Explores BIMI Implementation',
    content:
      'MailFence, known for its focus on privacy and security, is exploring the implementation of BIMI. While not yet fully adopted, MailFence recognizes the potential of BIMI to enhance email authentication and provide users with visual brand verification, aligning with their commitment to user security and experience.',
  },
];

const BimiInteractive = () => {
  const [activeProvider, setActiveProvider] = useState(null);

  return (
    <section id="bimiInteractive" className="section bg-neutral-100 position-relative">
      <div className="container-default w-container">
        <div className="position-relative">
          <div className="position-relative z-index-1">
            <div className="inner-container _72 center _100---tablet">
              <div className="inner-container _700px---tablet center">
                <div className="inner-container _500px---mbl center">
                  <div className={`text-center bimiDynamic ${styles.dynamicContent}`}>
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={activeProvider ? activeProvider.name : 'default'}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className={styles.contentWrapper}
                      >
                        <div className="inner-container _680px center">
                          <div className="mg-bottom-24px">
                            <div className="text-100 bold color-accent-1 text-uppercase">
                              {activeProvider
                                ? activeProvider.eyebrow
                                : 'Mail Box Providers Supported'}
                            </div>
                          </div>
                          <h2 className={`display-2 ${styles.headline}`}>
                            {activeProvider
                              ? activeProvider.headline
                              : 'BIMI support from the most popular email providers.'}
                          </h2>
                        </div>
                        <p className={`mg-bottom-40px ${styles.content}`}>
                          {activeProvider
                            ? activeProvider.content
                            : "The world's most popular email clients already support BIMI and even more are on the way! You'll just need a VMC to access the BIMI protocol and all its benefits. Get one today to enhance visibility in the inbox."}
                        </p>
                      </motion.div>
                    </AnimatePresence>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.providerGrid}>
              {providers.map((provider) => (
                <motion.div
                  key={provider.name}
                  className={`image-wrapper image-above border-radius-18px ${styles.providerButton} ${activeProvider && activeProvider.name !== provider.name ? styles.inactive : ''}`}
                  whileHover={{ scale: 1.05 }}
                  onHoverStart={() => setActiveProvider(provider)}
                  onHoverEnd={() => setActiveProvider(null)}
                >
                  <Image
                    src={provider.image}
                    alt={`${provider.name} logo`}
                    width={100}
                    height={100}
                  />
                </motion.div>
              ))}
            </div>

            <div className={`buttons-row center ${styles.buttonWrapper}`}>
              <AnimatedButton
                href="/products/digicert-verified-mark-certificate"
                target="_blank"
                text="Buy Now"
              />
            </div>
          </div>
          <div className="position-absolute white-fade"></div>
        </div>
      </div>
      <div className="position-absolute section-card-top-bg"></div>
    </section>
  );
};

export default BimiInteractive;
