import React from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';
import styles from './AnimatedButton.module.scss';

const AnimatedButton = ({ href, target = '_self', text }) => {
  return (
    <motion.a
      href={href}
      target={target}
      style={{ display: 'inline-block', cursor: 'pointer', outline: 'none' }}
      whileHover={{
        outlineOffset: ['0px', '4px'],
        transition: {
          duration: 0.4,
          ease: 'easeInOut',
        },
      }}
      initial={{
        outline: '3px solid #247BFE',
        outlineOffset: '0px',
      }}
      className={`${styles.animatedButton} btn-primary w-button`}
    >
      {text} <span className="line-rounded-icon link-icon-right"></span>
    </motion.a>
  );
};

export default AnimatedButton;
