import React from 'react';
import { motion } from 'framer-motion';
import styles from './AnimateGrid.module.scss';

const AnimatedGrid = () => {
  const squares = [
    { id: 1, x: 130, y: 129.992, width: 85, height: 85 },
    { id: 2, x: 215, y: 384.992, width: 85, height: 85 },
    { id: 3, x: 385, y: 45.9922, width: 85, height: 84 },
    { id: 4, x: 470, y: 469.992, width: 84, height: 84 },
  ];

  return (
    <svg
      width="600"
      height="600"
      viewBox="0 0 600 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.animateGrid}
    >
      {squares.map((square) => (
        <motion.rect
          key={square.id}
          x={square.x}
          y={square.y}
          width={square.width}
          height={square.height}
          fill="#EFF4F8"
          initial={{ opacity: 0.1 }}
          animate={{ opacity: [0.1, 0.5, 0.1] }}
          transition={{
            duration: 4,
            repeat: Infinity,
            repeatType: 'mirror',
            delay: Math.random() * 2, // Random delay for each square
          }}
        />
      ))}
      <g opacity="0.3">
        <path d="M554.487 0V599.993" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M469.657 0V599.993" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M384.828 0V599.993" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M299.998 0V599.993" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M215.168 0V599.993" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M130.339 0V599.993" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M45.5093 0V599.993" stroke="#EFF4F8" strokeWidth="0.6" />
      </g>
      <g opacity="0.3">
        <path d="M599.993 554.484L0.000169158 554.484" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M599.993 469.655L0.000169158 469.655" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M599.993 384.826L0.000169158 384.826" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M599.993 299.996L0.000169158 299.996" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M599.993 215.166L0.000169158 215.166" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M599.993 130.337L0.000169158 130.337" stroke="#EFF4F8" strokeWidth="0.6" />
        <path d="M599.993 45.5073L0.000169158 45.5073" stroke="#EFF4F8" strokeWidth="0.6" />
      </g>
    </svg>
  );
};

export default AnimatedGrid;
